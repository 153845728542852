const theme = {
  adaptive: {
    main: {
      xl: '@media screen and (max-width: 1800px), screen and (max-height: 960px)',
      lg: '@media screen and (max-width: 1600px), screen and (max-height: 840px)',
      md: '@media screen and (max-width: 1350px), screen and (max-height: 730px)',
      sm: '@media screen and (max-width: 1150px), screen and (max-height: 630px)',
      smm: '@media screen and (max-width: 830px)',
      xs: '@media screen and (max-width: 360px)',
    },
  },
}

export default theme
