import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as Medium } from 'images/footer/medium-icon.svg'
import { ReactComponent as Telegram } from 'images/footer/telegram-icon.svg'
import { ReactComponent as Twitter } from 'images/footer/twitter-icon.svg'

const Footer: React.FunctionComponent = () => {
  return (
    <Root>
      <Cont>
        <LeftSide>
          <CopyRight>© 2022 Sunflower DAO</CopyRight>
          <Policy>Privacy Policy</Policy>
          <Terms>Terms of use</Terms>
        </LeftSide>
        <RightSide>
          <SocialBlock
            href={'https://t.me/SunflowerDAO_Community'}
            target={'_blank'}
          >
            <SocialIcon>
              <Telegram />
            </SocialIcon>
            <SocialText>TELEGRAM</SocialText>
          </SocialBlock>
          <SocialBlock
            href={
              'https://twitter.com/sunflower_dao?t=lmjZYJs9rA0hYPsDjRMLSw&s=09'
            }
            target={'_blank'}
          >
            <SocialIcon>
              <Twitter />
            </SocialIcon>
            <SocialText>TWITTER</SocialText>
          </SocialBlock>
          <SocialBlock
            href={'https://medium.com/sunflowerdao'}
            target={'_blank'}
          >
            <SocialIcon>
              <Medium />
            </SocialIcon>
            <SocialText>MEDIUM</SocialText>
          </SocialBlock>
        </RightSide>
      </Cont>
    </Root>
  )
}

export default Footer

const Root = styled.div`
  position: relative;
  width: 100%;
`

const Cont = styled.div`
  position: relative;
  display: flex;
  width: 1824px;
  margin: 0 auto;
  align-items: flex-end;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 1598px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 1368px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 1178px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 780px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 335px;
    flex-wrap: wrap;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 280px;
  }
`

const LeftSide = styled.div`
  display: flex;
  padding-bottom: 23px;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    display: block;
    padding-bottom: 337px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 17px;
    order: 1;
  }
`

const TextCss = css`
  position: relative;
  font-size: 16px;
  line-height: 18px;
  color: #141414;
  mix-blend-mode: normal;
  opacity: 0.3;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 14px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 12px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 11px;
    line-height: 12px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 13px;
    line-height: 15px;
    width: 100%;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: auto;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 11px;
    line-height: 12px;
  }
`

const CopyRight = styled.div`
  ${TextCss};
  margin-right: 113px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-right: 98px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-right: 84px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-right: 69px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-right: 0;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 100%;
    order: 2;
    margin-bottom: 0;
    margin-top: 15px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-top: 10px;
  }
`

const Policy = styled.a`
  display: block;
  outline: none;
  ${TextCss};
  margin-right: 35px;
  cursor: pointer;
  text-decoration: underline;
  pointer-events: none;
  opacity: 0;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-right: 31px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-right: 26px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-right: 23px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-right: 0;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    margin-right: 25px;
    margin-bottom: 0;
    order: 1;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-right: 15px;
  }
  &:hover {
    color: #fb775b;
    opacity: 0.75;
  }
`

const Terms = styled.a`
  display: block;
  outline: none;
  ${TextCss};
  cursor: pointer;
  text-decoration: underline;
  pointer-events: none;
  opacity: 0;
  ${({ theme }) => theme.adaptive.main.smm} {
    order: 1;
  }
  &:hover {
    color: #fb775b;
    opacity: 0.75;
  }
`

const RightSide = styled.div`
  display: flex;
  margin-left: 289px;
  padding-bottom: 50px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-left: 252px;
    padding-bottom: 43px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-left: 217px;
    padding-bottom: 37px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-left: 174px;
    padding-bottom: 33px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-left: 20px;
    padding-bottom: 45px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 320px;
    margin: 0 auto;
    padding-bottom: 37px;
    justify-content: space-between;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 270px;
    padding-bottom: 36px;
  }
`

const SocialText = styled.div`
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.01px;
  text-transform: uppercase;
  color: #141414;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 14px;
    line-height: 15px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 12px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 11px;
    line-height: 11px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 12px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 11px;
    line-height: 11px;
  }
`

const SocialIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 13px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-bottom: 8px;
  }
  svg {
    display: block;
    width: 24px;
    height: 18px;
    fill: #141414;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.main.xl} {
      width: 20px;
      height: 15px;
    }
    ${({ theme }) => theme.adaptive.main.lg} {
      width: 17px;
      height: 13px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      width: 15px;
      height: 11px;
    }
    ${({ theme }) => theme.adaptive.main.sm} {
      width: 16px;
      height: 12px;
    }
    ${({ theme }) => theme.adaptive.main.xs} {
      width: 13px;
      height: 10px;
    }
  }
`

const SocialBlock = styled.a`
  position: relative;
  display: block;
  cursor: pointer;
  outline: none;
  margin-right: 90px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-right: 75px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-right: 65px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-right: 55px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-right: 40px;
  }
  //&:last-child {
  //  margin-right: 0;
  //}
  &:hover {
    ${SocialText} {
      color: #fb775b;
    }
    ${SocialIcon} {
      svg {
        fill: #fb775b;
      }
    }
  }
`
