import React from 'react'
import styled from 'styled-components'

const Main: React.FunctionComponent = () => {
  return (
    <Root>
      <Cont>
        <TitleWrap>
          <Title>
            Sunflower DAO is the Ultimate Algorithmic Liquidity Engine
          </Title>
          {/*<SubTitle>and Liquidity-backed Reserve Currency</SubTitle>*/}
          <GetStarted>
            <BtnText>Get started</BtnText>
          </GetStarted>
        </TitleWrap>
      </Cont>
    </Root>
  )
}

export default Main

const Root = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 256px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-bottom: 208px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-bottom: 200px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-bottom: 172px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding-top: 125px;
    margin-bottom: 157px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    padding-top: 56px;
    margin-bottom: 542px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    padding-top: 48px;
    margin-bottom: 458px;
  }
`

const Cont = styled.div`
  display: flex;
  width: 1660px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 1454px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 1246px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 1074px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 666px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 335px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 286px;
  }
`

const TitleWrap = styled.div`
  position: relative;
  width: 575px;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 496px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 416px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 358px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 416px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 325px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 277px;
  }
`

const Title = styled.div`
  font-size: 58px;
  line-height: 73px;
  color: #fcf8f8;
  margin-bottom: 12px;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 50px;
    line-height: 64px;
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 42px;
    line-height: 55px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 36px;
    line-height: 47px;
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 42px;
    line-height: 55px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    font-size: 32px;
    line-height: 43px;
    margin-bottom: 7px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 6px;
  }
`

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 36px;
  line-height: 40px;
  opacity: 0.8;
  color: #f8f1f1;
  margin-bottom: 100px;
  max-width: 450px;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 80px;
    max-width: 397px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 28px;
    line-height: 31px;
    margin-bottom: 70px;
    max-width: 341px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 55px;
    max-width: 293px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 28px;
    line-height: 31px;
    margin-bottom: 75px;
    max-width: 340px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 35px;
    max-width: 266px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 35px;
    max-width: 227px;
  }
`

const GetStarted = styled.button`
  position: relative;
  width: 105px;
  height: 50px;
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 92px;
    height: 44px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 87px;
    height: 38px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 75px;
    height: 33px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 87px;
    height: 38px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 80px;
    height: 35px;
    margin-left: 38px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 69px;
    height: 30px;
    margin-left: 32px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 409px;
    height: 51px;
    right: -109px;
    top: 0;
    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 76.89%
    );
    mix-blend-mode: exclusion;
    opacity: 0.35;
    border-radius: 50px;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.main.xl} {
      width: 358px;
      height: 45px;
      right: -95px;
      border-radius: 44px;
    }
    ${({ theme }) => theme.adaptive.main.lg} {
      width: 307px;
      height: 38px;
      right: -74px;
      border-radius: 38px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      width: 264px;
      height: 33px;
      right: -64px;
      border-radius: 32px;
    }
    ${({ theme }) => theme.adaptive.main.sm} {
      width: 307px;
      height: 38px;
      right: -74px;
      border-radius: 38px;
    }
    ${({ theme }) => theme.adaptive.main.smm} {
      width: 156px;
      height: 35px;
      right: -37px;
      border-radius: 34px;
      background: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0.25) 76.89%
      );
    }
    ${({ theme }) => theme.adaptive.main.xs} {
      width: 133px;
      height: 30px;
      right: -32px;
      border-radius: 30px;
    }
  }
  &:hover {
    &:before {
      background: linear-gradient(
        270deg,
        #ff5000 0%,
        rgba(255, 80, 0, 0) 76.35%
      );
    }
  }
`

const BtnText = styled.div`
  font-size: 21px;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 14px;
    line-height: 32px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 16px;
    line-height: 37px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    font-size: 14px;
    line-height: 34px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 12px;
    line-height: 29px;
  }
`
