import React from 'react'
import { BrowserRouter as RouterReact, Switch, Route } from 'react-router-dom'
import Index from 'components/index'

export default function Router() {
  return (
    <RouterReact>
      <Switch>
        <Route path="/">
          <Index />
        </Route>
      </Switch>
    </RouterReact>
  )
}
