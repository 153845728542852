import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import logoImg from 'images/header/logo.svg'
import { ReactComponent as Icon } from 'images/header/arrow-icon.svg'
import useWindowSize from 'helpers/utils/useWindowSize'
import useOnClickOutside from 'helpers/hooks/useOnClickOutside'
import { DECK } from 'configs/app'

const Header: React.FunctionComponent = () => {
  const ref = useRef(null)
  const [isDocs, setIsDocs] = useState(false)
  const { width } = useWindowSize()

  useOnClickOutside({
    ref: ref,
    handler: () => setIsDocs(false),
  })

  const List = (
    <NavList>
      {/*<NavItem>About Us</NavItem>*/}
      {/*<NavItem>Team</NavItem>*/}
      {/*<NavItem>News</NavItem>*/}
      <NavItem href={'mailto:contact@sunflowerdao.fi'} target={'_blank'}>
        Contact
      </NavItem>
    </NavList>
  )

  return (
    <Root>
      <Cont>
        <LeftSide>
          <Logo />
          {width > 830 && List}
        </LeftSide>
        <Docs ref={ref} open={isDocs} onClick={() => setIsDocs(!isDocs)}>
          <DocsPanel>
            {width <= 830 && List}
            <DocLinkRow href={DECK} target="_blank">
              <DocLink>Business Deck</DocLink>
              <DocIcon>
                <Icon />
              </DocIcon>
            </DocLinkRow>
            {/*<DocLinkRow>*/}
            {/*  <DocLink>Yellow Deck</DocLink>*/}
            {/*  <DocIcon>*/}
            {/*    <Icon />*/}
            {/*  </DocIcon>*/}
            {/*</DocLinkRow>*/}
          </DocsPanel>
          <DocBtnBg />
          <DocsText>{width > 830 ? 'Documents' : 'Menu'}</DocsText>
          <DocsCount>
            <Counter>1</Counter>
          </DocsCount>
        </Docs>
      </Cont>
    </Root>
  )
}

export default Header

interface DocsProps {
  open?: boolean
}

const Root = styled.div`
  position: relative;
  width: 100%;
`

const Cont = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1660px;
  margin: 0 auto;
  padding-top: 70px;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 1454px;
    padding-top: 58px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 1246px;
    padding-top: 50px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 1074px;
    padding-top: 42px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 780px;
    padding-top: 56px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 335px;
    padding-top: 28px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 286px;
    padding-top: 24px;
  }
`

const LeftSide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Logo = styled.div`
  width: 364px;
  height: 53px;
  margin-right: 69px;
  background: url('${logoImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 319px;
    height: 46px;
    margin-right: 61px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 274px;
    height: 40px;
    margin-right: 50px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 238px;
    height: 35px;
    margin-right: 42px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 234px;
    height: 34px;
    margin-right: 43px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 185px;
    height: 27px;
    margin-right: 45px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 158px;
    height: 23px;
    margin-right: 52px;
  }
`

const NavList = styled.div`
  display: flex;
  ${({ theme }) => theme.adaptive.main.smm} {
    display: block;
  }
`

const NavItem = styled.a`
  position: relative;
  display: block;
  font-size: 21px;
  line-height: 23px;
  color: #141414;
  margin-right: 75px;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 18px;
    line-height: 20px;
    margin-right: 65px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 16px;
    line-height: 18px;
    margin-right: 56px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 14px;
    line-height: 16px;
    margin-right: 48px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 13px;
    line-height: 14px;
    margin-right: 35px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 20px;
    margin-right: 0;
    text-align: right;
    color: #ffffff;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 16px;
    color: #ffffff;
  }
  &:last-child {
    margin-right: 0;
  }
  &:before {
    content: '';
    width: 0;
    height: 2px;
    position: absolute;
    top: 100%;
    left: 0;
    background: #141414;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.main.smm} {
      content: unset;
    }
  }
  &:hover:before {
    width: 100%;
  }

  &:not(:hover):before {
    right: 0;
    left: auto;
  }
`

const DocsPanel = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: -32px;
  left: 50%;
  transform: translateX(-50%);
  width: 262px;
  height: 185px;
  background: #020303;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  transition: 0.4s;
  opacity: 0;
  pointer-events: none;
  padding: 30px 36px;
  ${({ theme }) => theme.adaptive.main.xl} {
    top: -28px;
    width: 230px;
    height: 160px;
    padding: 22px 27px;
    border-radius: 9px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    top: -24px;
    width: 198px;
    height: 135px;
    padding: 19px 23px;
    border-radius: 8px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    top: -21px;
    width: 170px;
    height: 110px;
    padding: 15px 19px;
    border-radius: 6px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    top: -19px;
    width: 160px;
    height: 105px;
    padding: 14px 18px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    top: -15px;
    right: -14px;
    left: unset;
    transform: unset;
    width: 247px;
    height: 220px;
    padding: 42px 21px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    top: -14px;
    right: -17px;
    width: 197px;
    height: 170px;
    padding: 17px 21px;
  }
`

const DocLinkRow = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
  outline: none;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-top: 10px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-top: 8px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-top: 7px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-top: 6px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    margin-top: 20px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-top: 16px;
  }
`

const DocLink = styled.div`
  font-weight: 300;
  font-size: 21px;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    font-size: 22px;
    line-height: 25px;
    order: 2;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 18px;
    line-height: 20px;
  }
`

const DocIcon = styled.div`
  width: 16px;
  height: 20px;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 15px;
    height: 17px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 12px;
    height: 15px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 11px;
    height: 13px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 11px;
    height: 13px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 20px;
    height: 24px;
    order: 1;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 16px;
    height: 20px;
  }
  svg {
    width: 100%;
    height: 100%;
    transition: 0.4s;
    fill: #ff5000;
  }
`

const DocBtnBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(#020303, #020303 50%, #ffffff 50%, #ffffff);
  background-size: 100% 200%;
  border-radius: 50px;
  transition: 0.4s;
  cursor: pointer;
`

const DocsText = styled.div`
  position: relative;
  font-size: 21px;
  line-height: 49px;
  text-align: center;
  color: #ffffff;
  pointer-events: none;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 18px;
    line-height: 43px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 16px;
    line-height: 37px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 14px;
    line-height: 32px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 13px;
    line-height: 30px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    font-size: 15px;
    line-height: 32px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 12px;
    line-height: 26px;
  }
`

const Docs = styled.div<DocsProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 202px;
  height: 51px;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 177px;
    height: 45px;
    border-radius: 44px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 152px;
    height: 38px;
    border-radius: 38px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 130px;
    height: 33px;
    border-radius: 32px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 123px;
    height: 31px;
    border-radius: 30px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 95px;
    height: 27px;
    border-radius: 30px;
    z-index: 3;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 67px;
    height: 21px;
    border-radius: 26px;
  }
  ${DocBtnBg} {
    background-position: ${({ open }) => (open ? '100% 100%' : '100% 200%')};
  }
  ${DocsText} {
    color: ${({ open }) => (open ? '#020303' : '#ffffff')};
  }
  ${DocsPanel} {
    opacity: ${({ open }) => (open ? 1 : 0)};
    pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
  }
  &:hover {
    ${DocBtnBg} {
      background-position: 100% 100%;
    }
    ${DocsText} {
      color: #020303;
    }
    ${DocsPanel} {
      opacity: 1;
      pointer-events: auto;
    }
  }
`

const DocsCount = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  right: 3px;
  width: 27px;
  height: 27px;
  background: #ff5000;
  border-radius: 50%;
  ${({ theme }) => theme.adaptive.main.xl} {
    top: -4px;
    right: 3px;
    width: 24px;
    height: 24px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    right: 2px;
    width: 20px;
    height: 20px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    top: -3px;
    width: 17px;
    height: 17px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 16px;
    height: 16px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    display: none;
  }
`

const Counter = styled.div`
  font-size: 15px;
  line-height: 49px;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 14px;
    line-height: 43px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 12px;
    line-height: 37px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 12px;
    line-height: 32px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 10px;
    line-height: 30px;
  }
`
