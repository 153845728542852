import React from 'react'
import styled from 'styled-components'
import Header from '../header'
import Main from './Main'
import Footer from '../footer'
import centerBg from 'images/index/center-right.svg'
import centerBgSm from 'images/index/center-right-sm.svg'
import centerBgSmm from 'images/index/center-right-smm.svg'
import { ReactComponent as Plus } from 'images/index/plus.svg'
import footerRight from '../../images/footer/footer-right.svg'
import footerLeft from '../../images/footer/footer-left.svg'
import footerLeftSm from '../../images/footer/footer-left-sm.svg'
import footerLeftSmm from '../../images/footer/footer-left-smm.svg'
import headLeftBg from 'images/header/header-left.svg'
import headCenterBg from 'images/header/header-center.svg'
import mobEth from 'images/index/mob-eth.svg'
import Noise from '../common/Noise'
import useWindowSize from 'helpers/utils/useWindowSize'

const Index: React.FunctionComponent = () => {
  const { width, height } = useWindowSize()

  return (
    <Root>
      <Cont>
        <HeadLeftBg />
        <HeadCenterBg />
        <LeftBg />
        <RightBg />
        <EthBg />
        <PlusRow>
          <PlusWrap>
            <Plus />
          </PlusWrap>
          <PlusWrap>
            <Plus />
          </PlusWrap>
          <PlusWrap>
            <Plus />
          </PlusWrap>
        </PlusRow>
        <BottomPlusRow>
          <PlusWrap>
            <Plus />
          </PlusWrap>
          <PlusWrap>
            <Plus />
          </PlusWrap>
          <PlusWrap>
            <Plus />
          </PlusWrap>
        </BottomPlusRow>
        {width > 1150 && height >= 630 && <Noise />}
        {width <= 830 && <MobEth />}
      </Cont>
      <Header />
      <div>
        <Main />
        <Footer />
      </div>
    </Root>
  )
}

export default Index

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  ${({ theme }) => theme.adaptive.main.sm} {
    overflow: auto;
    overflow-x: hidden;
    justify-content: start;
  }
`

const Cont = styled.div`
  position: absolute;
  width: 1920px;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 1680px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 1440px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 1240px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 666px;
    height: 1100px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 375px;
    height: 1000px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 320px;
    height: 800px;
  }
`

const EthBg = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -322px;
  width: 1167px;
  height: 1355px;
  background: url('${centerBg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.main.xl} {
    right: -268px;
    width: 992px;
    height: 1152px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    right: -242px;
    width: 875px;
    height: 1016px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    right: -208px;
    width: 759px;
    height: 881px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    background: url('${centerBgSm}') center no-repeat;
    background-size: cover;
    top: 453px;
    transform: unset;
    right: -97px;
    width: 540px;
    height: 708px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    background: url('${centerBgSmm}') center no-repeat;
    background-size: cover;
    top: 419px;
    transform: unset;
    right: 0px;
    width: 453px;
    height: 427px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    top: 366px;
    transform: unset;
    right: 0px;
    width: 385px;
    height: 363px;
  }
`

const PlusRow = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  right: 497px;
  transform: translateY(-50%);
  mix-blend-mode: difference;
  ${({ theme }) => theme.adaptive.main.xl} {
    right: 435px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    right: 373px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    right: 321px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    right: 31px;
    top: 473px;
    transform: unset;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    right: 121px;
    top: 291px;
    transform: unset;
    display: block;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    right: 103px;
    top: 248px;
  }
`

const PlusWrap = styled.div`
  width: 68px;
  height: 68px;
  margin-right: 214px;
  mix-blend-mode: difference;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 59px;
    height: 59px;
    margin-right: 188px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 51px;
    height: 51px;
    margin-right: 161px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 45px;
    height: 45px;
    margin-right: 138px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 44px;
    height: 44px;
    margin-right: 123px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    width: 38px;
    height: 38px;
    margin-right: 0;
    margin-bottom: 68px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 32px;
    height: 32px;
    margin-bottom: 58px;
  }
  &:last-child {
    margin: 0;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
    stroke: #007f9b;
    fill: #007f9b;
    mix-blend-mode: difference;
    path {
      mix-blend-mode: difference;
    }
  }
`

const RightBg = styled.div`
  position: absolute;
  bottom: 0;
  right: -322px;
  width: 400px;
  height: 17px;
  background: url('${footerRight}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.main.xl} {
    right: -268px;
    width: 340px;
    height: 14px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    right: -242px;
    width: 300px;
    height: 13px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    right: -208px;
    width: 260px;
    height: 11px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    right: -325px;
    width: 300px;
    bottom: unset;
    top: 1115px;
    height: 13px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    right: -225px;
    width: 300px;
    height: 13px;
    bottom: unset;
    top: 1027px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    right: -110px;
    width: 260px;
    height: 11px;
    bottom: unset;
    top: 880px;
  }
`

const LeftBg = styled.div`
  position: absolute;
  width: 1462px;
  height: 805px;
  bottom: 0;
  left: -322px;
  background: url('${footerLeft}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.main.xl} {
    left: -268px;
    width: 1243px;
    height: 684px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    left: -242px;
    width: 1097px;
    height: 604px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    left: -208px;
    width: 950px;
    height: 523px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    left: -97px;
    top: 132px;
    bottom: unset;
    width: 732px;
    height: 992px;
    background: url('${footerLeftSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    left: 0px;
    top: 71px;
    bottom: unset;
    width: 508px;
    height: 764px;
    background: url('${footerLeftSmm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    left: 0px;
    top: 59px;
    bottom: unset;
    width: 432px;
    height: 650px;
  }
`

const BottomPlusRow = styled.div`
  display: flex;
  position: absolute;
  bottom: 56px;
  right: 158px;
  mix-blend-mode: difference;
  ${({ theme }) => theme.adaptive.main.xl} {
    bottom: 46px;
    right: 142px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    bottom: 42px;
    right: 127px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    bottom: 36px;
    right: 101px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    top: 1045px;
    bottom: unset;
    right: 27px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    top: 805px;

    right: 36px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    top: 687px;
    right: 27px;
  }
  ${PlusWrap} {
    width: 44px;
    height: 44px;
    margin-right: 145px;
    ${({ theme }) => theme.adaptive.main.xl} {
      width: 39px;
      height: 39px;
      margin-right: 127px;
    }
    ${({ theme }) => theme.adaptive.main.lg} {
      width: 33px;
      height: 33px;
      margin-right: 109px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      width: 29px;
      height: 29px;
      margin-right: 94px;
    }
    ${({ theme }) => theme.adaptive.main.sm} {
      width: 30px;
      height: 30px;
      margin-right: 81px;
      margin-bottom: 0;
    }
    ${({ theme }) => theme.adaptive.main.smm} {
      width: 35px;
      height: 35px;
      margin-right: 93px;
    }
    ${({ theme }) => theme.adaptive.main.xs} {
      width: 30px;
      height: 30px;
      margin-right: 80px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

const HeadLeftBg = styled.div`
  position: absolute;
  width: 395px;
  height: 17px;
  top: 0;
  right: calc(100% - 48px);
  background: url('${headLeftBg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 336px;
    height: 14px;
    right: calc(100% - 42px);
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 296px;
    height: 13px;
    right: calc(100% - 36px);
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 257px;
    height: 11px;
    right: calc(100% - 31px);
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 296px;
    height: 13px;
    right: calc(100% + 47px);
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    right: calc(100% - 53px);
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 257px;
    height: 11px;
    right: calc(100% - 53px);
  }
`

const HeadCenterBg = styled.div`
  position: absolute;
  width: 994px;
  height: 17px;
  top: 0;
  left: 513px;
  background: url('${headCenterBg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 819px;
    height: 14px;
    left: 450px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 759px;
    height: 13px;
    left: 385px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 643px;
    height: 11px;
    left: 332px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 759px;
    height: 13px;
    left: 288px;
  }
  ${({ theme }) => theme.adaptive.main.smm} {
    left: 110px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 643px;
    height: 11px;
    left: 94px;
  }
`

const MobEth = styled.div`
  position: absolute;
  top: 406px;
  left: 42px;
  width: 158px;
  height: 256px;
  background: url('${mobEth}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.main.xs} {
    top: 346px;
    left: 42px;
    width: 136px;
    height: 221px;
  }
`
