import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body, html, #root {
    position: relative;
    scroll-behavior: smooth;
    height: 100%;
  }

  body {
    font-family: 'Sansation';
    min-height: 100vh;
    min-height: -webkit-fill-available;
    margin: 0;
    -webkit-font-smoothing: antialiased;
  }

  button {
    border: 0;
    background: none;
    padding: 0;
    outline: none;
    cursor: pointer;
  }

  input {
    background: none;
    border-radius: 8px;
    outline: none;
  }

  textarea {
    outline: none;
  }

  a {
    text-decoration: none;
  }
`
export default GlobalStyle
