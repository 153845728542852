import { createGlobalStyle } from 'styled-components'
import SansationRegularWoff2 from './fonts/Sansation-Regular.woff2'
import SansationRegularWoff from './fonts/Sansation-Regular.woff'
import SansationLightWoff2 from './fonts/Sansation-Light.woff2'
import SansationLightWoff from './fonts/Sansation-Light.woff'

const sansation = {
  regular: {
    woff2: SansationRegularWoff2,
    woff: SansationRegularWoff,
  },
  light: {
    woff2: SansationLightWoff2,
    woff: SansationLightWoff,
  },
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Sansation';
    src: local('Sansation'),
    url(${sansation.regular.woff2}) format('woff2'),
    url(${sansation.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sansation';
    src: local('Sansation'),
    url(${sansation.light.woff2}) format('woff2'),
    url(${sansation.light.woff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
`
export default Fonts
